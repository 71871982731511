import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Table, Button, Input, message } from "antd";
import { AiOutlineSave } from "react-icons/ai";
import {
  GetSalaryslip,
  GetSalaryslipTable,
  GetSalaryslipforouttertable,
} from "../store/dataSlice";
import { toggleNewDialog, toggleDrower } from "../store/stateSlice";
import { apiGetAllHoliday } from "../../../services/HolidayService";
import { apipostSalaryslip } from "../../../services/SalarySlipService";
import dayjs from "dayjs";
import moment from "moment";

function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

const SalaryTableinner = ({ year, month }) => {
  const dispatch = useDispatch();
  const [holidaysCount, setHolidaysCount] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [totalWorkinghour, setTotalWorkinghour] = useState(0);
  const [totalWorkinghourBefore12, setTotalWorkinghourBefore12] = useState(0);

  const date = dayjs().month(month - 1);
  const tableData = useSelector(
    (state) => state.salaryslip.data.salaryslipList.data
  );

  const loading = useSelector(
    (state) => state.salaryslip?.data?.loading || false
  );

  useEffect(() => {
    if (Array.isArray(tableData)) {
      setData(tableData);
    } else {
      setData([]);
    }
  }, [tableData]);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await apiGetAllHoliday({
          YEAR: dayjs(year).format("YYYY"),
          MONTH: date.format("MM"),
        });

        // console.log("response", response.data.count);
        setHolidaysCount(response.data.count || 0); // Ensure fallback if count is not present
      } catch (error) {
        console.error("Error fetching holidays:", error);
        setHolidaysCount(0);
      }
    };

    fetchHolidays();
    dispatch(
      GetSalaryslip({
        YEAR: dayjs(year).format("YYYY"),
        MONTH: date.format("MM"),
      })
    );
    dispatch(toggleNewDialog(false));
  }, [dispatch, year, month]);

  const calculateAttendance = (record) => {
    let totalPresentDays = 0;
    let totalSalary = 0;
    let halfDayCount = 0;
    let workedHours = 0;
    let halfDaySalary = 0;

    const dayInStandardTime = record["DAYIN_TIME"];
    const dayOutStandardTime = record["DAYOUT_TIME"];
    const dayIntervalTime = record["INTERVAL_TIME"];

    function timeStringToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }

    // Convert times to seconds
    const dayInSeconds = timeStringToSeconds(dayInStandardTime);
    const dayOutSeconds = timeStringToSeconds(dayOutStandardTime);

    const diffInSeconds = dayOutSeconds - dayInSeconds;

    const standardWorkingHours = diffInSeconds / 3600;

    const halfStandardWorkingHours = standardWorkingHours / 2;

    const endOfMonth = getDaysInMonth(record["YEAR"], record["MONTH"]);

    for (let i = 1; i <= endOfMonth; i++) {
      const dayInKey = `DAYIN_${i}`;
      const dayOutKey = `DAYOUT_${i}`;
      const dayInTime = record[dayInKey] || null;
      const dayOutTime = record[dayOutKey] || null;

      if (dayInTime && dayOutTime) {
        totalPresentDays += 1;

        workedHours =
          moment(dayOutTime, "HH:mm:ss").diff(
            moment(dayInTime, "HH:mm:ss"),
            "seconds"
          ) / 3600;

        if (
          workedHours >= halfStandardWorkingHours &&
          workedHours < standardWorkingHours
        ) {
          halfDayCount += 1;
        }
      }
    }

    totalPresentDays = totalPresentDays + holidaysCount;

    const totalDays = getDaysInMonth(record["YEAR"], record["MONTH"]);

    let totalHours = 0;
    let after12TotalHours = 0;
    let totalLeave = 0;

    for (let i = 1; i <= endOfMonth; i++) {
      const dayIn = record[`DAYIN_${i}`];
      const dayOut = record[`DAYOUT_${i}`];

      if (dayIn && dayOut) {
        if (dayIn && dayOut && dayIn <= "12:00:00") {
          const diff = moment(dayOut, "HH:mm:ss").diff(
            moment(dayIn, "HH:mm:ss")
          );

          const hours = Math.floor(diff / 3600000);
          const minutes = Math.floor((diff % 3600000) / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);

          const minutesInHours = minutes / 60;
          const secondsInHours = seconds / 3600;

          const netDurationInSeconds = hours + minutesInHours + secondsInHours;

          totalHours += netDurationInSeconds;
        } else if (dayIn && dayOut) {
          const diff = moment(dayOut, "HH:mm:ss").diff(
            moment(dayIn, "HH:mm:ss")
          );

          const hours = Math.floor(diff / 3600000);
          const minutes = Math.floor((diff % 3600000) / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);

          const minutesInHours = minutes / 60;
          const secondsInHours = seconds / 3600;

          const netDurationInSeconds = hours + minutesInHours + secondsInHours;

          after12TotalHours += netDurationInSeconds;

          if (dayIn > "12:00:00") {
            totalLeave += 1;
          }
        }
      } else {
        totalLeave += 1;
      }
    }

    totalLeave = totalLeave - holidaysCount;

    let leaveHours = 0;

    if (totalLeave === 1) {
      leaveHours = standardWorkingHours;
    }

    const monthlySalaries = parseFloat(record.SALARY) || 0;
    const perDaySalary = monthlySalaries / endOfMonth;
    const perHourSalary = perDaySalary / standardWorkingHours;

    const absentDays = parseFloat(totalDays) - parseFloat(totalPresentDays);

    const holidayInHours = holidaysCount * standardWorkingHours;

    if (halfDayCount === 1 && totalLeave === 0) {
      halfDaySalary = perHourSalary * workedHours;
    } else if (halfDayCount === 2 && totalLeave === 0) {
      halfDaySalary = standardWorkingHours * perDaySalary;
    }

    const actualPayableSalary = (
      (totalHours + holidayInHours + leaveHours) *
      perHourSalary
    ).toFixed(2);

    const actualPayableSalaryAfter12 = (
      (after12TotalHours + holidayInHours) *
      perHourSalary
    ).toFixed(2);

    return {
      totalPresentDays,
      absentDays,
      holidaysCount,
      actualPayableSalary,
      actualPayableSalaryAfter12,
      standardWorkingHours,
      perHourSalary,
      holidayInHours,
      after12TotalHours,
      totalHours,
    };
  };

  const processedData = Array.isArray(data)
    ? data.map((record, index) => {
        const {
          totalPresentDays,
          absentDays,
          payableSalary,
          actualPayableSalary,
          actualPayableSalaryAfter12,
          standardWorkingHours,
          perHourSalary,
          holidayInHours,
          after12TotalHours,
          totalHours,
          holidaysCount,
        } = calculateAttendance(record);
        return {
          ...record,
          TOTAL_PRESENT_DAYS: totalPresentDays,
          TOTAL_ABSENCES: absentDays,
          PAYBLE_SALARY: payableSalary,
          ACTUAL_PAYABLE_SALARY: actualPayableSalary,
          PER_HOUR_SALARY: perHourSalary,
          HOLIDAY_HOURS: holidayInHours,
          WORKING_HOURS_BEFORE_12: totalHours.toFixed(4),
          WORKING_HOURS: after12TotalHours.toFixed(4),
          TOTAL_WORKING_HOURS: (totalHours + holidayInHours).toFixed(4),
          ACTUAL_PAYABLE_SALARY_AFTER_12: actualPayableSalaryAfter12,
          STANDARD_WORKING_HOURS: standardWorkingHours,
          FULL_NAME: `${record.FIRST_NAME} ${record.MIDDLE_NAME} ${record.LAST_NAME}`,
          index,
        };
      })
    : [];

  const handleSave = async () => {
    try {
      const detailsData = processedData.map((record) => ({
        EMP_ID: record.EMP_ID,
        TOTAL_PRESENT_DAYS: record.TOTAL_PRESENT_DAYS,
        TOTAL_ABSENT_DAYS: record.TOTAL_ABSENCES,
        PAYABLE_SALARY: record.SALARY,
        STANDARD_WORKING_HOURS: record.STANDARD_WORKING_HOURS,
        PER_HOUR_SALARY: record.PER_HOUR_SALARY,
        HOLIDAY_HOURS: record.HOLIDAY_HOURS,
        WORKING_HOURS_BEFORE_12: record.WORKING_HOURS_BEFORE_12,
        WORKING_HOURS: record.WORKING_HOURS,
        TOTAL_WORKING_HOURS: record.TOTAL_WORKING_HOURS,
        ACTUAL_PAYABLE_SALARY: record.ACTUAL_PAYABLE_SALARY,
        ACTUAL_PAYABLE_SALARY_AFTER_12: record.ACTUAL_PAYABLE_SALARY_AFTER_12,
        REMARK: record.REMARK,
      }));

      const dataToSend = {
        MONTH: date.format("MM"),
        YEAR: dayjs(year).format("YYYY"),
        detailsData: detailsData,
      };

      // console.log("Data to send:", dataToSend);

      const response = await apipostSalaryslip(dataToSend);
      // console.log("Salary slips posted successfully:", response);
      // message.success("Salary slips saved successfully!");
      // dispatch(GetSalaryslipTable({ pageIndex: 1, pageSize: 10 }));
      // console.log("Salary slips posted successfully:", response.status, response.data.code);

      if (response.status >= 200) {
        if (response.data.code === 200) {
          // console.log("Salary slips posted successfully:", response);
          message.success("Salary slips saved successfully!");
          // dispatch(GetSalaryslipTable({ pageIndex: 1, pageSize: 10 }));
          dispatch(GetSalaryslipforouttertable({ pageIndex: 1, pageSize: 10 }));
          dispatch(toggleDrower(false));
        } else if (response.data.code === 304) {
          message.info("Salary slip already generated.");
        } else {
          message.error("Error occurred during form submission.");
        }
      } else {
        message.error("Error occurred during form submission.");
      }
    } catch (error) {
      console.error("Error posting salary slips:", error);
      message.error("Failed to save salary slips. Please try again.");
    }
  };

  let totalWorkingHours = 0;

  const calculateWorkingHours = (records) => {
    totalWorkingHours = 0;
    records.forEach((record) => {
      for (let i = 1; i <= 31; i++) {
        const dayIn = record[`DAYIN_${i}`];
        const dayOut = record[`DAYOUT_${i}`];

        if (dayIn && dayOut) {
          const diff = moment(dayOut, "HH:mm:ss").diff(
            moment(dayIn, "HH:mm:ss")
          );

          const hours = Math.floor(diff / 3600000);
          const minutes = Math.floor((diff % 3600000) / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);

          const minutesInHours = minutes / 60;
          const secondsInHours = seconds / 3600;

          totalWorkingHours += hours + minutesInHours + secondsInHours;

          setTotalWorkinghour(totalWorkingHours);
        }
      }
    });
  };

  const calculateWorkingHoursAfter12 = (records) => {
    totalWorkingHours = 0;
    records.forEach((record) => {
      for (let i = 1; i <= 31; i++) {
        const dayIn = record[`DAYIN_${i}`];
        const dayOut = record[`DAYOUT_${i}`];

        if (dayIn && dayOut && dayIn <= "11:59:59") {
          const diff = moment(dayOut, "HH:mm:ss").diff(
            moment(dayIn, "HH:mm:ss")
          );

          const hours = Math.floor(diff / 3600000);
          const minutes = Math.floor((diff % 3600000) / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);

          const minutesInHours = minutes / 60;
          const secondsInHours = seconds / 3600;

          totalWorkingHours += hours + minutesInHours + secondsInHours;

          setTotalWorkinghourBefore12(totalWorkingHours);
        }
      }
    });
  };

  const columns = [
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "FULL_NAME",
      key: "fullName",
      width: 200,
      fixed: "left",
    },
    // {
    //   title: <span className="text-gray-500">Total Present Days</span>,
    //   dataIndex: "TOTAL_PRESENT_DAYS",
    //   key: "totalPresentDays",
    //   render: (text) => (text !== null ? text : 0),
    //   width: 160,
    // },
    // {
    //   title: <span className="text-gray-500">Total Absent Days</span>,
    //   dataIndex: "TOTAL_ABSENCES",
    //   key: "totalAbsences",
    //   render: (text) => (text !== null ? text : 0),
    //   width: 160,
    // },

    {
      title: <span className="text-gray-500">Total Holiday Hours</span>,
      dataIndex: "",
      align: "center",
      width: 220,
      render: (record) => {
        const holidayCount = holidaysCount;
        return <span>{holidayCount * record.STANDARD_WORKING_HOURS}</span>;
      },
    },

    {
      title: <span className="text-gray-500">Working Hours</span>,
      dataIndex: "",
      align: "center",
      width: 180,
      render: (value, record) => {
        calculateWorkingHours([record]);
        return totalWorkingHours.toFixed(4);
      },
    },
    {
      title: (
        <span className="text-gray-500">Working Hours before Day In 12</span>
      ),
      dataIndex: "",
      align: "center",
      width: 180,
      render: (value, record) => {
        calculateWorkingHoursAfter12([record]);
        return totalWorkingHours.toFixed(4);
      },
    },

    {
      title: <span className="text-gray-500">Total Working Hours</span>,
      dataIndex: "",
      align: "center",
      width: 220,
      render: (value, record) => {
        calculateWorkingHoursAfter12([record]);
        return (
          totalWorkingHours +
          holidaysCount * record.STANDARD_WORKING_HOURS
        ).toFixed(4);
      },
    },

    {
      title: "Per Hour Salary",
      dataIndex: "PER_HOUR_SALARY",
      key: "PER_HOUR_SALARY",
      width: 180,
      align: "center",
      render: (text, record) => <span>{text.toFixed(2)}</span>,
    },

    {
      title: "Salary",
      dataIndex: "SALARY",
      key: "SALARY",
      width: 180,

      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Actual Payable Salary",
      dataIndex: "ACTUAL_PAYABLE_SALARY",
      width: 220,

      key: "ACTUAL_PAYABLE_SALARY",
      render: (text, record) => (
        <Input
          value={text}
          // You can implement onChange here if needed
        />
      ),
    },
    {
      title: "Actual Payable Salary after DayIn 12",
      dataIndex: "ACTUAL_PAYABLE_SALARY_AFTER_12",
      key: "ACTUAL_PAYABLE_SALARY_AFTER_12",
      width: 220,

      render: (text, record) => (
        <Input
          value={text}
          // You can implement onChange here if needed
        />
      ),
    },
    {
      title: "Remarks",
      dataIndex: "REMARK",
      key: "REMARK",
      width: 400,

      render: (text, record) => (
        <Input
          value={text}
          // You can implement onChange here if needed
        />
      ),
    },
  ];

  return (
    <div className="salary-table-inner">
      <Spin spinning={loading}>
        <Table
          dataSource={processedData}
          columns={columns}
          scroll={{ x: 1500 }}
          pagination={{
            current: currentPage,
            pageSizeOptions: [5, 10, 20, 50],
            pageSize: pageSize,
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            },
          }}
          rowKey={(record) => record.index}
        />
      </Spin>
      <Button
        type="primary"
        icon={<AiOutlineSave />}
        onClick={handleSave}
        style={{ marginTop: 16 }}
      >
        Save
      </Button>
    </div>
  );
};

export default SalaryTableinner;
